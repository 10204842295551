import React, {Component} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import TeamDetails from './section-components/team-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import Testimonial from "./section-components/testimonial-v1";

class Team_Details extends Component {

  constructor(props) {
    super(props);
    this.state = {agent: null, name: '', title: '', img: ''};

    let publicUrl = process.env.PUBLIC_URL+'/'
    console.log('load', publicUrl, document.location.href)

    const agent = document.location.href.split('?').pop();
    const agentDetails = this.getDetails(agent);

    console.log(agentDetails)

    this.state.agent = agent;
    this.state.name = agentDetails?.name;
    this.state.title = agentDetails?.title;
    this.state.img = agentDetails?.img;
    this.state.email = agentDetails?.email;
    this.state.phone = agentDetails?.phone;
    this.state.introduction = agentDetails?.introduction;

    console.log('componentDidMount', this.state.name, this.state.title, this.state.img)
  }

  componentDidMount() {

    const $ = window.$;

  }

  getDetails(name) {
    console.log('getDetails', name)
    let result = null;
    switch(name) {
      case 'chloe_zang':
        result = {
          name: 'Chloe Zang',
          img: 'assets/img/team/chloe_zang.webp',
          title: 'Director',
          email: 'chloe.zang@hoyee.com',
          phone: '0430 034 988',
          introduction:
            '<p>Drawing upon a profound passion and extensive expertise in the real estate industry, Chloe has developed experience and achievements second to none in Melbourne\'s dynamic property market.</p>' +
            '<p>Chloe has successfully developed & executed sales and marketing strategies, teams and campaigns to sell over $1 .3 billion dollars worth of properties in Melbourne CBD and Metro area since 2013.</p>' +
            '<p>As the visionary founder and director of Hoyee Property Group, Chloe brings strong leadership and a very hands on approach to team planning and execution of sales and marketing campaigns. This commitment holds true whether it\'s for a large-scale project or someone’s home or investment.</p>'
        }
        break
      case 'gavin_liu':
        result = {
          name: 'Gavin Liu',
          img: 'assets/img/team/gavin_liu.webp',
          title: 'Sales Director',
          email: 'gavin.liu@hoyee.com',
          phone: '0406 679 777',
          introduction:
            '<p>With over 15 years of experience in the real estate and property development industry, Gavin Liu embarked on his career journey with the pioneering property developer Central Equity. Contributed himself for more than a dozen projects throughout Melbourne Regions, encompassing apartments, house and land packages, as well as standalone houses.</p>' +
            '<p>Later, Gavin took on the role of Head of the Asian market at Samma Group, one of Victoria\'s top 5 developers. This position provided him with invaluable insights into business management, particularly in the domains of sales and marketing. His profound knowledge of property investment has facilitated numerous successful transitions for individuals settling into their new homes across the Victoria Metro Area.</p>' +
            '<p>Skilled at conducting lively, effective approaches Gavin\'s clients have come to appreciate his uncanny ability to extract the absolute maximum possible value for each and every property that he handles. His intuitive understanding of buyer motivations combined with a personable but firm negotiation style inevitably leads to benchmark prices being achieved.</p>' +
            '<p>As a sales director of Hoyee Property Group, Gavin takes personal pride in, and contributes enormous individual energy to providing each and every client with a rewarding real estate experience.</p>'
        }
        break
      case 'esther_zhang':
        result = {
          name: 'Esther Zhang',
          img: 'assets/img/team/esther_zhang.webp',
          title: 'Financial Controller',
          email: 'accounts@hoyee.com.au',
          phone: '03 8743 3342',
          introduction:
            '<p>Introducing Esther Zhang, the esteemed Financial Controller of Hoyee Property Group. With a wealth of experience and expertise in financial management, Esther plays a pivotal role in ensuring the fiscal success of our ventures. Her astute financial acumen and meticulous attention to detail are the cornerstones of her contributions to our team.</p>' +
            '<p>Esther brings a wealth of knowledge gained from years of dedicated service in the real estate industry. Her proficiency in navigating the complexities of financial operations within the property sector is unparalleled. Her strategic insights and steadfast commitment to maintaining financial integrity have been instrumental in driving the success of Hoyee Property Group.</p>' +
            '<p>With Esther at the financial helm, you can trust in our unwavering dedication to transparency, accuracy, and financial prudence. Her expertise is an invaluable asset, ensuring that every financial aspect of our projects and transactions is managed with precision and care. We are privileged to have Esther as an integral part of our team, contributing significantly to the continued growth and prosperity of Hoyee Property Group.</p>',
        }
        break
      case 'michael_pang':
        result = {
          name: 'Michael Pang',
          img: 'assets/img/team/michael_pang.webp',
          title: 'Senior Property Manager',
          email: 'h.pm@hoyee.com.au',
          phone: '0411 984 500',
          introduction:
            '<p>Michael has accumulated a decade of experience in the real estate industry, working in various roles within several construction companies, real estate development firms, and property sales companies. Michael’s career journey includes positions as a Business Development Manager, Sales Manager, and Leasing Manager. </p>' +
            '<p>As the Senior Property Manager now at Hoyee Property Group, Michael Oversees a wide range of responsibilities, including property leasing, property management, and the resolution of disputes between tenants and landlords. Also helping the landlords to  manage their real estate investments, always striving to maximize investment returns for property owners while maintaining the lowest operational costs.</p>' +
            '<p>Collaboration is key, and Michael works closely with the property sales department to ensure the successful pre-marketing preparation of properties set for listing. This ensures that sale campaign is well-prepared to achieve maximum sales prices, providing an invaluable service to property owners.</p>' +
            '<p>Extensive experience in the real estate sector provides Michael a comprehensive understanding of the industry, enabling Michael to navigate its complexities and deliver results. Michael is driven by a commitment to achieving the highest level of customer satisfaction and financial success for all stakeholders involved.</p>',
        }
        break
      case 'michelle_luo':
        result = {
          name: 'Michelle Luo',
          img: 'assets/img/team/michelle_luo.webp',
          title: 'Property Specialist',
          email: 'michelle.luo@hoyee.com.au',
          phone: '0412 568 136',
          introduction:
            '<p>Meet Michelle Luo, an experienced property specialist at Hoyee Property Group with years of accounting and real estate expertise. Fluent in English, Mandarin, and Cantonese, she offers a unique edge in serving diverse clientele.</p>' +
            '<p>Michelle\'s unwavering dedication ensures personalized and professional service that exceeds expectations. She believes in tailoring experiences to the individual needs and aspirations of clients.</p>' +
            '<p>Her mission is guiding clients through significant real estate decisions, whether finding their dream home or selling a property. She simplifies complex processes, making them smooth, stress-free, and ultimately rewarding.</p>' +
            '<p>Michelle\'s passion extends beyond properties to the people she serves. Her genuine love for service drives her to make a positive impact on clients\' lives, helping them achieve their real estate goals."</p>'
        }
        break
      case 'ying_zheng':
        result = {
          name: 'Ying Zheng',
          img: 'assets/img/team/ying_zheng.webp',
          title: 'Operation Manager',
          email: 'admin@hoyee.com.au',
          phone: '0413 936 690',
          introduction:
            '<p>Ying joined the real estate industry after graduate from university in 2013. Been working for an industry-leading property development company in Melbourne for 8 years, she has extensive experience in administration roles at different departments including but not limited to sales, marketing, telemarketing, data entry, project management, conveyancing etc, her experience in various aspects of the industry is truly valuable. Not only does she sit in the office and manage the day-to-day operations of the office, but Ying also frequently flies interstate and overseas to the frontline providing administration support to the sales team and immediate response to clients.</p>' +
            '<p>After giving birth to her first baby in 2021, Ying took a break from work and started her new role in motherhood.</p>' +
            '<p>In 2022, Ying returned to the workforce and Joined Hoyee Property Group as Operation Manager. She oversees the daily office operations and simultaneously contributes her experience to advancing the company’s marketing strategies and enhancing customer communication. She plays a crucial role in supporting the team to uphold the highest standard of excellence and professionalism.</p>',
        }
        break
      case 'ray_pu':
        result = {
          name: 'Ray Pu',
          img: 'assets/img/team/ray_pu.webp',
          title: 'Property Assistant | Operation',
          email: 'h.pm8@hoyee.com.au',
          phone: '0408 001 767',
          introduction:
            '<p>Ray is our dedicated Property Assistant in the Operation Department at Hoyee Property Group. With a keen eye for detail and a passion for excellence, Ray plays a vital role in ensuring the seamless operation of our daily real estate tasks. </p>' +
            '<p>Ray has years of marketing experience and a strong passion for real estate. She takes charge of several key responsibilities, including managing company marketing updates, providing invaluable support in property management, and offering essential day-to-day operational assistance. As a team member of Hoyee Property Group,  Ray brings a commitment to delivering exceptional service and a positive attitude to every task.</p>',
        }
        break
      case 'frankie_leung':
        result = {
          name: 'Frankie Leung',
          img: '',
          title: 'Sales Manager',
          email: 'frankie.l@hoyee.com.au',
          phone: '0402 009 088',
          introduction: '',
        }
        break
      case 'stuart_morton':
        result = {
          name: 'Stuart Morton',
          img: '',
          title: 'Business Development Manager',
          email: 'projects@hoyee.com.au',
          phone: '0404 456 935',
          introduction: '',
        }
        break
      case 'david_chen':
        result = {
          name: 'David Chen',
          img: 'assets/img/team/david_chen.webp',
          title: 'Business Development Manager',
          email: 'bdm@hoyee.com.au',
          phone: '0438 795 891',
          introduction: '',
        }
        break
      default:
        break
    }

    return result
  }

  render() {
    return <div>
      <Navbar/>
      <PageHeader headertitle="Agent Details"/>
      <TeamDetails
        name={this.state.name}
        title={this.state.title}
        img={this.state.img}
        email={this.state.email}
        phone={this.state.phone}
        introduction={this.state.introduction}
      />
      <Testimonial agent={this.state.agent}/>
      <CallToActionV1/>
      <Footer/>
    </div>
  }
}

export default Team_Details

